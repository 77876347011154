import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionType, ProColumns } from "@ant-design/pro-components";
import { Button, Card } from "antd";

import { DeleteFilled, KeyOutlined } from "@ant-design/icons";

import { AntDProTable } from "../../../components/antdProTable";

import { SubscriptionSchema } from "../../../model/subscriptionSchema.schema";
import { subscriptionService } from "../../../services/subscriptionService";
import moment from "moment";
import Swal from "sweetalert2";

//Start
export function AllSubscriptionSummaryPage(prop: { subscription: string }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  let tableRef = useRef<ActionType>();

  const columns: ProColumns<SubscriptionSchema>[] = [
    {
      title: t("Action"),
      dataIndex: "_id",
      hideInSearch: true,
      hideInTable: false,
      sorter: false,
    },
    {
      title: t("Count"),
      dataIndex: "count",
      hideInSearch: true,
      defaultSortOrder:"descend",
      hideInTable: false,
      sorter: false,
   
    }
  ];

  return (
    <>
      <Card style={{ marginTop: 20 }} loading={loading}>
        <AntDProTable<SubscriptionSchema>
          filterType={"light"}
          size={"small"}
          onRefCallback={(ref: any) => {
            tableRef.current = ref.current;
          }}
          pageSizeOptions={[10, 20, 50, 100, 200]}
          columns={columns}
          rowKey="id"
          scroll={{ x: "max-content" }}
         
          request={(
            params,
            sort,
            filter,
            skip,
            take,
            where,
            sortBy,
            order,
            cb
          ) => {
            /// setLoading(true);
            where.subscription = prop.subscription;
            subscriptionService
              .listAllSubscriptionSummary(prop.subscription)
              .then((res) => {
                //setLoading(false);
                if (res.success) {
                  cb({
                    data: res.result,
                    total: res.recordTotal,
                    success: true,
                    page: params.current,
                  });
                } else {
                  cb({
                    data: [],
                    total: 0,
                    success: false,
                    page: params.current,
                  });
                }
              })
              .catch((err) => {
                setLoading(false);
                cb({
                  data: [],
                  total: 0,
                  success: false,
                  page: params.current,
                });
              });
          }}
        />
      </Card>
    </>
  );
}
